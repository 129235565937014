import React from 'react';
import { useSelector } from 'react-redux';

import GoodDogValueBanner from 'dpl/components/BreederProfile/Show/GoodDogValueBanner';
import AboutBreedModule from 'dpl/components/BreederProfile/Show/AboutBreedModule';

import BreederProfileAboutPuppiesSectionContainer from '../BreederProfileAboutPuppiesSectionContainer';
import BreederProfileAdoptionProcessSectionContainer from '../BreederProfileAdoptionProcessSectionContainer';
import BreederProfileCurrentPuppiesSectionContainer from '../BreederProfileCurrentPuppiesSectionContainer';
import BreederProfileDeliverySectionContainer from '../BreederProfileDeliverySectionContainer';
import BreederProfileHealthTestsSectionContainer from '../BreederProfileHealthTestsSectionContainer';
import BreederProfileLittersSectionContainer from '../BreederProfileLittersSectionContainer';
import BreederProfileMainContainer from '../BreederProfileMainContainer';
import BreederProfileMoreAboutBreederSectionContainer from '../BreederProfileMoreAboutBreederSectionContainer';
import BreederProfileOwnerStoriesContainer from '../BreederProfileOwnerStoriesContainer';
import BreederProfileParentsSectionContainer from '../BreederProfileParentsSectionContainer';
import BreederProfilePhotosSectionContainer from '../BreederProfilePhotosSectionContainer';
import BreederProfileRelatedBreedsContainer from '../BreederProfileRelatedBreedsContainer';
import BreederProfileUpdatesSectionContainer from '../BreederProfileUpdatesSectionContainer';

export default function BreederProfileOverviewTabContainer() {
  const { isSearchEngineReferral } = useSelector(({ server }) => server);

  return (
    <div className="BreederProfileOverviewTabContainer">
      <BreederProfileMainContainer />
      <BreederProfileCurrentPuppiesSectionContainer />
      <BreederProfileLittersSectionContainer />
      {isSearchEngineReferral && <GoodDogValueBanner />}
      <BreederProfileUpdatesSectionContainer />
      <BreederProfileAdoptionProcessSectionContainer />
      <BreederProfileAboutPuppiesSectionContainer />
      <BreederProfileDeliverySectionContainer />
      <BreederProfileMoreAboutBreederSectionContainer />
      <BreederProfileParentsSectionContainer />
      <BreederProfileHealthTestsSectionContainer />
      <BreederProfileOwnerStoriesContainer />
      <BreederProfilePhotosSectionContainer />
      {isSearchEngineReferral && <AboutBreedModule />}
      <BreederProfileRelatedBreedsContainer />
    </div>
  );
}
